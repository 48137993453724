const Testimonialdata=[
    {
        name:"Sweety John",
        review:"We really had a good experience with a spacious hall, catering experience and especially the decoration overall. Thank you so much once again"
    },
    {
        name:"Sarika Jain",
        review:"The place is awesome and specious.. and the food is delicious.. really had a good event..And the decoration was also beautiful..will definitely recommend Aarna Swarna banquet"
    },
    {
        name:"Akshay Khandekar",
        review:"Overall hall management is good all the staff including the management staff are polite to talk and always there to help us"
    },
    {
        name:"Godwin Remedios",
        review:"Great place to host events. Management was very cooperative throughout the process. Thanks to Adarsh, Dinesh & team."
    },
    {
        name:"Ranjith Kumar",
        review:"Aadarsh & his team had done a wonderful job in executing the event flawlessly from the beginning till the end. Amazing service, good ambience, delicious & tasty food, awesome Hospitality, elegant decorations and themes...all up to the mark. "
    },
    {
        name:"Ankita Bamrotia",
        review:"Our experience was very good in every category known to catering and banquet hall and we feel extremely fortunate to have had such a warm and fuzzy experience at this banquet hall, our wedding looked and went fantastic."
    },
    {
        name:"Saeed Ahmed Shabbir Ahmed",
        review:"Find awesome place as a banquet in vasai as a jewellery exibition ever some one expect no parking tension and other facilities.thanks to all"
    },
    {
        name:"JO BHAGAT",
        review:"Had my baby girl’s first birthday party & everything was perfect.Amazing service, spacious place & the food 😍 superb quality, the variety & everything was upto the mark. Loved it & would surely recommend everyone for any kind of celebrations !"
    },
    {
        name:"Nikhil Shetty",
        review:"Would really like to appreciate the support provided by the entire team. Especially Adarsh was there to help us out with everything. The food was excellent. The staff was very cooperative and helped us step up the decor as per our needs."
    },
    {
        name:"Dr. Tufail Ahmed Shaikh",
        review:"Nice banquet hall location is also good to reached out side of vasai peoples. Sometimes AC totally off... But hall was to good."
    },
]

export default Testimonialdata