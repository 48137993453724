import React from 'react'
import Hadder from '../components/Hadder'
import Footer from '../components/Footer'
import { NavLink } from 'react-router-dom'
const Notfound = () => {
  return (
    <>
    <Hadder/>
        <div className='container' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div style={{marginTop:'150px'}} className='mb-5'>
        <h1 className=''>Page Not Found</h1>
        <NavLink to='/'>
        <button className=' mt-5 btn btn-dark'>Return to Home</button>
        </NavLink>
        </div>
        </div>
    <Footer/>
    </>
  )
}

export default Notfound
