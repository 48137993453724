import React, { useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import "../css/Hadder.css";
import logo from "../asset/logo.png";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LiaFacebookF, LiaTwitter, LiaInstagram } from "react-icons/lia";
import ServiceDataa from "./ServiceDataa";
const Hadder = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <>
      <div className="nav-bar-component">
        <section className="NavSection">
          {/* <div className="container"> */}
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Brand href="#">
                <div className="NavLogo">
                  <Link to="/" className="logo1">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </Navbar.Brand>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-start"
              >
                <Nav>
                  <Nav.Link href="#home">
                    <Link to="/"> Home</Link>
                  </Nav.Link>
                  <Nav.Link href="#Gallery">
                    <Link to="/about-us">About us</Link>
                  </Nav.Link>

                  <NavDropdown title="Services" id="collapsible-nav-dropdown">
                    {ServiceDataa.map((ele) => {
                      const { title, Slugs } = ele;
                      return (
                        <NavDropdown.Item key={Slugs} href={Slugs}>
                          <Link to={Slugs}>{title}</Link>
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <Nav.Link href="#About us">
                    <Link
                      to="/banquet-near-vasai
                                    "
                    >
                      Contact us
                    </Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Nav className="Social-media-icons">
                <a href="https://www.facebook.com/aarnasuvarnabanquets/">
                  <div
                    className="fa1"
                    style={{
                      borderRadius: "30px",
                      border: "0.5px solid goldenrod",
                      padding: "4px",
                    }}
                  >
                    <LiaFacebookF
                      style={{ color: "goldenrod", fontSize: "25px" }}
                    />
                  </div>
                </a>
              </Nav>
              <Nav className="Social-media-icons">
                <a href="https://www.instagram.com/aarnaswarnabanquets/">
                  <div
                    className="fa1"
                    style={{
                      borderRadius: "30px",
                      border: "0.5px solid goldenrod",
                      padding: "4px",
                    }}
                  >
                    <LiaInstagram
                      style={{ color: "goldenrod", fontSize: "25px" }}
                    />
                  </div>
                </a>
              </Nav>
            </Container>
          </Navbar>
          {/* </div> */}
        </section>
      </div>
    </>
  );
};

export default Hadder;
