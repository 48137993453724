import React, { useEffect, useRef } from 'react';
import Fotter from '../components/Footer'
import { Link } from "react-router-dom";
import Hadder from '../components/Hadder';
import NumberCounter from "number-counter";
import { Swiper, SwiperSlide } from "swiper/react";
import video11 from "../asset/hader.mp4"
import ONE from "../asset/ICON/ONE.png"
import TWO from "../asset/ICON/TWO.png"
import THREE from "../asset/ICON/THREE.png"
import FOUR from "../asset/ICON/FOUR.png"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import ab1 from "../asset/aboutus/about1.JPG"
import G9 from "../asset/Gallery/G7.jpg"
import G4 from "../asset/Gallery/G6.jpg"
import { MetaTags } from 'react-meta-tags';
import Testimonialdata from '../components/Testimonialdata';
const Aboutus = () => {
    const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video) {
        video.play().catch(error => {
          // Handle the play error if needed
          console.error('Failed to play the video:', error);
        });
      }
    };

    const handleInteraction = () => {
      // Check if the user has interacted with the document
      if (
        document.visibilityState === 'visible' &&
        document.hasFocus() &&
        video
      ) {
        playVideo();
        document.removeEventListener('visibilitychange', handleInteraction);
        document.removeEventListener('click', handleInteraction);
      }
    };

    // Listen for document visibility change or click event
    document.addEventListener('visibilitychange', handleInteraction);
    document.addEventListener('click', handleInteraction);

    // Cleanup event listeners when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleInteraction);
      document.removeEventListener('click', handleInteraction);
    };
  }, []);
  return (
    <div>
      <MetaTags>
        <title>About Us | Aarna Swarna Banquet Hall in Vasai </title>
        <meta title=" About Us | Aarna Swarna Banquet Hall in Vasai " />
        <meta name="description" content="Discover Aarna Swarna Banquet Hall in Vasai, a top venue for weddings, parties, and events, known for elegant spaces and exceptional service." />
        <meta name="keywords" content="best-banquet-vasai,top-banquet-vasai,wedding-banquet-vasai,best-banquet-vasai-birthday-parties,vasai-banquet-hall-corporate-events,best-banquet-family-function-vasai,banquet-near-vasai" />
        <link rel="canonical" href="https://aarnaswarna.in/about-us" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="About Us | Aarna Swarna Banquet Hall in Vasai " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aarnaswarna.in/" />
        <meta property="og:description" content="Discover Aarna Swarna Banquet Hall in Vasai, a top venue for weddings, parties, and events, known for elegant spaces and exceptional service." />
        <meta property="og:image" content="https://aarnaswarna.in/static/media/logo.d48820a0b2dc856d591b.png" />
      </MetaTags>
      <Hadder/>
   
      <div
        className="breadcrumb-area breadcrumb-padding bg-img"
        style={{
          backgroundImage:
            `url(${G4})`,
        }}
      >
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h1 style={{color:"white"}}>About Us</h1>
            {/* <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right" />
              </li>
              <li>About Us</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="row row-cols-lg-2 row-cols-1 mb-n6">
            <div className="col-md-6 align-self-center mb-6 order-md-1 order-2">
              <div className="about-content margintop10">
                <h3 className="title textalign">
                Welcome to aarna swarna banquet hall
                </h3>
                <p className="textalign" style={{textAlign:"justify"}}>
                Welcome to The Aarna Swarna banquet, the best banquet in vasai, where dreams come to life and celebrations become cherished memories. Nestled in the heart of elegance, our best banquet in vasai is the perfect canvas for a myriad of events. Whether you're envisioning a fairytale wedding, a joyous birthday bash, a sophisticated corporate gathering, or a heartwarming family function, the best banquet in vasai is here to turn your vision into reality.
                </p>
              </div>
              <div className="working-away-wrap">
                <div className="working-away">
                  <div>
                    <h3 className="title textalign">
                    Aarna Swarna Banquet{" "}
                    </h3>
                    <p className="textalign" style={{textAlign:"justify"}}>
                    Our best banquet in vasai is a harmonious blend of opulence and functionality. With versatile spaces, tasteful decor, and state-of-the-art facilities, we cater to the diverse needs of our clients. From intimate ceremonies to grand galas, we have the capacity to host events of varying scales, ensuring a seamless and memorable experience for all.   
                    </p>
                  </div>
                </div>
            
              </div>
              <div className="textalign">
               
              </div>
            </div>
            <div className="col-md-6 mb-6 order-md-2 order-1">
              <div className="about-banner">
              <video id="myVideo" className='videowiddthhight' controls>
        <source src={video11} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section section-padding bg-img overly-style-1 opacity-point-7"
        style={{
          backgroundImage:
          `url(${ab1})`,
        }}
      >
        <div className="container">
          <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={ONE} alt="Banquet near Vasai"/>
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={594}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={500}
                                delay={4}
                                className="displayflex"
                              />
                              +
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Our Client</h4>
              </div>
            </div>
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={TWO} alt="Wedding Banquet near Vasai" />
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={100}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={254}
                                delay={4}
                                className="displayflex"
                              />
                              +
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Reviews</h4>
              </div>
            </div>
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={THREE} alt="Top Wedding Banquet in Vasai"/>
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={785}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={50}
                                delay={4}
                                className="displayflex"
                              />
                              k
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Lowest Price</h4>
              </div>
            </div>
            <div className="col mb-8">
              <div className="funfact-2 text-center">
                <img className="iconwidth1" src={FOUR} alt="Banquet for Birthday Party in Vasai" />
                <span
                  className="count-style-2 odometer odometer-auto-theme"
                  data-count-to={542}
                >
                  <div className="odometer-inside">
                    <span className="odometer-digit">
                      <span className="odometer-digit-inner">
                        <span className="odometer-ribbon">
                          <span className="odometer-ribbon-inner">
                            <span className="odometer-value">
                              <NumberCounter
                                end={578}
                                delay={4}
                                className="displayflex"
                              />
                              +
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
                <h4 className="title">Caterer</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
     
   
<div className="contectus">
        <div className="container">
        <div className="row" style={{padding:"20px"}}>
          <div className="col-md-9" style={{padding:"20px"}}>
            <h3>
          <div className="bannertext">
          <div className="animate one">
    <span>
    Step into a world where moments unfold, and memories blossom—elevate your celebrations at our banquet hall</span>
    
    
</div>





        </div>
        </h3>
          </div>
          
          <div className="col-md-3" style={{padding:"20px" }}>
          <a
     
     className="btn btn-primary btn-hover-dark aos-init aos-animate"
     data-aos="fade-up"
     data-aos-delay={400}
   >
    <Link to="/banquet-near-vasai" style={{color:"white"}}>Enquery now</Link>
     
   </a>
          </div>
        </div>
        </div>

      </div>
      <div
        className="section section-padding bg-img overly-style-1 opacity-point-7"
        style={{
          backgroundImage:
          `url(${G9})`,
        }}
      >
        <div className="container">
          <div className="section-title text-center mb-lg-9 mb-md-7 mb-5">
            <h2 className="title white">
              WHAT <span> CLIENTS</span> SAY?
            </h2>
            <p className="white">
            Our Vasai banquet hall surpasses expectations with elegant ambiance and impeccable service. Clients love it for dream weddings, corporate events, and family functions. Grateful testimonials affirm our commitment to excellence.
            </p>
          </div>
          <div className="testimonial-active swiper swiper-initialized swiper-horizontal swiper-pointer-events">
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-a4481288903ee2ec"
              aria-live="polite"
            >
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1000: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {Testimonialdata.map((data)=>(
                  <SwiperSlide>
                  <div className="Swiper-con">
                    <div
                      className="swiper-slide swiper-slide-duplicate"
                      data-swiper-slide-index={2}
                      role="group"
                      aria-label="3 / 4"
                      // style={{ width: 370 }}
                    >
                      <div className="single-testimonial-wrapper">
                        <div className="testimonial-img-title">
                          <div className="testimonial-img">
                            <img
                              src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-3.png"
                              alt="Best Banquet for Family Function near Vasai"
                            />
                          </div>
                          <div className="testimonial-title">
                            <h4>{data.name}</h4>
                            
                          </div>
                        </div>
                        <p>
                          {data.review}
                        </p>
                        <div className="quote">
                          <i className="fa fa-quote-left" />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                ))}
                
               
              </Swiper>
              
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </div>

      <Fotter/>
    </div>
  )
}

export default Aboutus
