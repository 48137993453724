

import birthday from "../asset/service/birthday.jpeg"
import coprate from "../asset/service/coprate.jpeg"
import wedding from "../asset/service/wedding.jpg"
import family from "../asset/service/family.jpg"

const Servicedata = [
    {
        id: 1,
        ServiceName: "Wedding",
        ServiceSubName:"Palnner",
        ServiceImage:wedding,
    },
    {
        id: 2,
        ServiceName: "Birthday",
        ServiceSubName:"Party",
        ServiceImage: birthday ,
    },
    {
        id: 3,
        ServiceName: "Corporate ",
        ServiceSubName:"Events",
        ServiceImage:coprate,
    },
    {
        id: 3,
        ServiceName: "Family",
        ServiceSubName:"Events",
        ServiceImage:family,
    },
   
   
  
];


export default Servicedata;