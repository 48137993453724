import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import hader from "../asset/hader.mp4";
import "../css/style.css";
import Hadder from "../components/Hadder";
import NewAboutUs from "../components/NewAboutUs";
import Footer from "../components/Footer";
import Servicesecton from "../components/Servicesecton";
import Slide from "react-reveal/Slide";
import "../css/home.css";
import lightGallery from "lightgallery";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
  import "lightgallery/css/lightgallery.css";
  import "lightgallery/css/lg-zoom.css";
  import "lightgallery/css/lg-thumbnail.css";
  import "lightgallery/css/lg-fullscreen.css";
  import "lightgallery/css/lg-pager.css";
  import "lightgallery/css/lg-autoplay.css";
import NumberCounter from "number-counter";
import ONE from "../asset/ICON/ONE.png";
import TWO from "../asset/ICON/TWO.png";
import THREE from "../asset/ICON/THREE.png";
import FOUR from "../asset/ICON/FOUR.png";
import Enquerynow from "../components/Enquerynow";
import { FaParking } from "react-icons/fa";
import { MdOutlineFoodBank } from "react-icons/md";
import { BiSolidCctv } from "react-icons/bi";
import "../css/hoeee.css"
import { MdCheckroom } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { LiaChairSolid } from "react-icons/lia";
import ab1 from "../asset/aboutus/about1.JPG"
import G1 from "../asset/Gallery/G1.JPG"
import G2 from "../asset/Gallery/G2.JPG"
import G3 from "../asset/Gallery/G3.JPG"
import G4 from "../asset/Gallery/G4.jpg"
import G5 from "../asset/Gallery/G5.jpeg"
import G6 from "../asset/Gallery/G6.jpg"
import G9 from "../asset/Gallery/G7.jpg"
import { MetaTags } from 'react-meta-tags';
import Testimonialdata from "../components/Testimonialdata";
const Home = () => {
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  return (
    <div>
      <MetaTags>
        <title>Best banquet hall in vasai west​| Banquet halls in mumbai - Aarna Swarna Banquet </title>
        <meta title="Best banquet hall in vasai west​| Banquet halls in mumbai - Aarna Swarna Banquet" />
        <meta name="description" content="Find the best banquet halls in Vasai West, Aarna Swarna is one of the top venues in Vasai virar, along with luxury and premier banquet halls in Mumbai for your events." />
        <meta name="keywords" content="best-banquet-vasai,top-banquet-vasai,wedding-banquet-vasai,best-banquet-vasai-birthday-parties,vasai-banquet-hall-corporate-events,best-banquet-family-function-vasai,banquet-near-vasai" />
        <link rel="canonical" href="https://aarnaswarna.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best banquet hall in vasai west​| Banquet halls in mumbai - Aarna Swarna Banquet " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aarnaswarna.in/" />
        <meta property="og:description" content="Find the best banquet halls in Vasai West, Aarna Swarna is one of the top venues in Vasai virar, along with luxury and premier banquet halls in Mumbai for your events." />
        <meta property="og:image" content="https://aarnaswarna.in/static/media/logo.d48820a0b2dc856d591b.png" />
      </MetaTags>
      <Hadder></Hadder>

      <div className="hero-area overly-style-1 opacity-point-4">
        <video
          className="video-active"
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
        >
          <source src={hader} type="video/mp4" />
        </video>
        <div className="hero-content-1 hero-content-position">
          <h1
            className="title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            Best Banquet Halls in Vasai - AARNA SWARNA BANQUET
          </h1>
          <p
            data-aos="fade-up"
            data-aos-delay={300}
            className="aos-init aos-animate"
          >
           Let's turn your vision into a reality. Book with us for a celebration that transcends expectations. Your dream event begins here!{" "}
          </p>
          <a
            className="btn btn-primary btn-hover-dark aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <Link to="/banquet-near-vasai" style={{color:"white"}}>VIEW MORE</Link>
          </a>
        </div>
      </div>

      <NewAboutUs></NewAboutUs>
      <div>
        <div
          className="section section-padding bg-img overly-style-1 opacity-point-7"
          style={{
            paddingBottom: "40px",
            paddingTop: "40px",
            backgroundImage:`url(${ab1})`,
          }}
        >
          <div className="container">
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
              <div className="col mb-8" style={{marginBottom:"10px"}}>
                <div className="funfact-2 text-center">
                  <img className="iconwidth1" src={ONE} alt="Best Banquet in Vasai" />
                  <span
                    className="count-style-2 odometer odometer-auto-theme"
                    data-count-to={594}
                  >
                    <div className="odometer-inside">
                      <span className="odometer-digit">
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">
                                <NumberCounter
                                  end={500}
                                  delay={4}
                                  className="displayflex"
                                />
                                +
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  <h4 className="title">Our Client</h4>
                </div>
              </div>
              <div className="col mb-8" style={{marginBottom:"10px"}}>
                <div className="funfact-2 text-center">
                  <img className="iconwidth1" src={TWO} alt="top Banquet in Vasai" />
                  <span
                    className="count-style-2 odometer odometer-auto-theme"
                    data-count-to={100}
                  >
                    <div className="odometer-inside">
                      <span className="odometer-digit">
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">
                                <NumberCounter
                                  end={254}
                                  delay={4}
                                  className="displayflex"
                                />
                                +
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  <h4 className="title">Reviews</h4>
                </div>
              </div>
              <div className="col mb-8" style={{marginBottom:"10px"}}>
                <div className="funfact-2 text-center">
                  <img className="iconwidth1" src={THREE} alt="Banquet for Wedding in Vasai" />
                  <span
                    className="count-style-2 odometer odometer-auto-theme"
                    data-count-to={785}
                  >
                    <div className="odometer-inside">
                      <span className="odometer-digit">
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">
                                <NumberCounter
                                  end={50}
                                  delay={4}
                                  className="displayflex"
                                />
                                k
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  <h4 className="title">Lowest Price</h4>
                </div>
              </div>
              <div className="col mb-8" style={{marginBottom:"10px"}}>
                <div className="funfact-2 text-center">
                  <img className="iconwidth1" src={FOUR} alt="Best Banquet for Birthday Party in Vasai" />
                  <span
                    className="count-style-2 odometer odometer-auto-theme"
                    data-count-to={542}
                  >
                    <div className="odometer-inside">
                      <span className="odometer-digit">
                        <span className="odometer-digit-inner">
                          <span className="odometer-ribbon">
                            <span className="odometer-ribbon-inner">
                              <span className="odometer-value">
                                <NumberCounter
                                  end={578}
                                  delay={4}
                                  className="displayflex"
                                />
                                +
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </span>
                  <h4 className="title">Caterer</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "35vh" }}>
        <Servicesecton />
      </div>

      <section id="about-banner">
  <div className="welcome">
    {/* <div class="headcontent">
          <h2>Our Facilities</h2>
        
      </div> */}
    {/* <div class="wlcimg">
          <img src="./assets/images/divider_title.webp" alt="">
      </div> */}
  </div>
  <section className='Service-Section-2' style={{backgroundColor:"white"}}>
      <div style={{textAlign:"center",display:"flex",justifyContent:"center"}}>
<div className='NewAbout-Section-con-details-Info-con'>
                                            <h2 className='NewAboutUs-tagLlines' style={{textAlign:"center"}}>Amenity</h2>
                                            <h3>Amenity</h3>
                                            </div>
                                            </div>
        <div className='container'>
        <div className="container">
    <div className="row" id="FACILITY">
   
      <div className="col-lg-2 col-md-6 col-6">
        <div className="faci-cont" id="facbox1">
          <div className="fac-icon">
            <div className="iconfont">
            <FaParking />
            </div>
          </div>
          <h3>Car Parking</h3>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 col-6">
        <div className="faci-cont" id="facbox2">
          <div className="fac-icon">
            <div className="iconfont">
            <MdOutlineFoodBank />
            </div>
          </div>
          <h3>Catering Inhouse</h3>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 col-6">
        <div className="faci-cont" id="facbox3">
          <div className="fac-icon">
            <div className="iconfont">
            <BiSolidCctv />
            </div>
          </div>
          <h3>Cctv Camera</h3>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 col-6">
        <div className="faci-cont">
          <div className="fac-icon">
            <div className="iconfont">
            <MdCheckroom />
            </div>
          </div>
          <h3>Changing Rooms</h3>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 col-6">
        <div className="faci-cont">
          <div className="fac-icon">
            <div className="iconfont">
            <TbAirConditioning />
            </div>
          </div>
          <h3>Air Conditioner Halls</h3>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 col-6">
        <div className="faci-cont">
          <div className="fac-icon">
            <div className="iconfont">
            <LiaChairSolid />
            </div>
          </div>
          <h3>Proper Sitting Arrangements </h3>
        </div>
      </div>
    </div>
    <nav className="navbar navbar-expand-lg navbar-light homeLine ">
      <div className="container"></div>
    </nav>
  </div>
        </div>
      </section>
 
</section>


      <section className="Service-Section-2">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "50px",
          }}
        >
          <div className="NewAbout-Section-con-details-Info-con">
            <h2
              className="NewAboutUs-tagLlines"
              style={{ textAlign: "center" }}
            >
              Gallery
            </h2>
            <h3>Gallery</h3>
          </div>
        </div>
        <div className="container">
          <div id="image-gallery" className="row">
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              href={G1}
              data-lg-size="1600-2400"
              data-lg-srcset={G1}
            >
              <img
                src={G1}
                style={{ height: "300px", width: "100%" }}
                alt="top Banquet for Birthday Party in Vasai"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={G2}
              data-lg-size="1600-2400"
              data-lg-srcset={G2}
            >
              <img
                src={G2}
                style={{ height: "300px", width: "100%" }}
                alt=" Banquet for Birthday Party in Vasai"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={G3}
              data-lg-size="1600-2400"
              data-lg-srcset={G3}
            >
              <img
                src={G3}
                style={{ height: "300px", width: "100%" }}
                alt="Best Banquet in Vasai"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={G4}
              data-lg-size="1600-2400"
              data-lg-srcset={G4}
            >
              <img
                src={G4}
                style={{ height: "300px", width: "100%" }}
                alt="Banquet near Vasai"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={G5}
              data-lg-size="1600-2400"
              data-lg-srcset={G5}
            >
              <img
                src={G5}
                style={{ height: "300px", width: "100%" }}
                alt=" Top Wedding Banquet in Vasai"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={G6}
              data-lg-size="1600-2400"
              data-lg-srcset={G6}
            >
              <img
                src={G6}
                style={{ height: "300px", width: "100%" }}
                alt="Best Banquet for Family Function in Vasai"
              />
            </a>
          </div>
        </div>
      </section>

      <div
        className="section section-padding bg-img overly-style-1 opacity-point-7"
        style={{
          backgroundImage:
            `url(${G9})`,
        }}
      >
        <div className="container">
          <div className="section-title text-center mb-lg-9 mb-md-7 mb-5">
            <h2 className="title white">
              WHAT <span> CLIENTS</span> SAY?
            </h2>
            <p className="white">
            
Our Vasai banquet hall surpasses expectations with elegant ambiance and impeccable service. Clients love it for dream weddings, corporate events, and family functions. Grateful testimonials affirm our commitment to excellence.
            </p>
          </div>
          <div className="testimonial-active swiper swiper-initialized swiper-horizontal swiper-pointer-events">
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-a4481288903ee2ec"
              aria-live="polite"
            >
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1000: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {Testimonialdata.map((data)=>(
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{ width: 370 }}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://htmldemo.net/drior/drior/assets/images/testimonial/client-3.png"
                            alt="top Banquet for Family Function in Vasai"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>{data.name}</h4>
                          
                        </div>
                      </div>
                      <p>
                          {data.review}
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>  
                ))}
                
                
              </Swiper>
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </div>

      <Enquerynow />
      <Footer />
    </div>
  );
};

export default Home;
